<template>
  <!-- 联系我们页面 -->
  <div class="index">
    <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="3" />
    <div class="context">
      <div class="backBigBox">
        <div
          id="backBox"
          :style="{
            left:
              topstate == 0
                ? '0'
                : topstate == 1
                ? '-120rem'
                : topstate == 2
                ? '-240rem'
                : '-360rem',
          }"
        >
          <div class="back">
            <img src="../imger/server/back01.png" alt="商标服务" />
            <div class="backBox">
              <span class="BackBoxTitle">注册前先查询，有效提升成功率</span>
              <div class="backBoxinput">
                <input
                  type="text"
                  name=""
                  placeholder="请输入您想要注册的商标名称"
                  class="input01"
                  v-model="inputData01"
                />
                <input
                  type="text"
                  name=""
                  placeholder="联系人"
                  class="input01"
                  v-model="inputData02"
                />
                <div class="inputBottom">
                  <input
                    type="text"
                    name=""
                    placeholder="联系电话"
                    class="input02"
                    v-model="inputData03"
                  />
                  <button id="bottom01" @click="tijiao()">
                    <img src="../imger/server/bottom01.png" alt="" />立即查询
                  </button>
                </div>
                <button id="bottom02">
                  <img src="../imger/server/bottom02.png" alt="" />购买商标
                </button>
              </div>
            </div>
          </div>
          <div class="back">
            <img src="../imger/server/back02.png" alt="品牌服务" />
          </div>
          <div class="back">
            <img src="../imger/server/back03.png" alt="专利版权" />
          </div>
          <div class="back">
            <img src="../imger/server/back04.png" alt="其他服务" />
          </div>
        </div>
      </div>

      <div class="content">
        <div class="tab">
          <div
            class="topback"
            :style="{
              left:
                topstate == 0
                  ? '0'
                  : topstate == 1
                  ? '18.75rem'
                  : topstate == 2
                  ? '37.5rem'
                  : '56.25rem',
            }"
          >
            <img src="../imger/server/topback.png" id="topback" alt="" />
            <div class="topbackBox">
              <img
                :src="topData[topstate].icon"
                id="top01"
                :alt="topData[topstate].wera"
              />
              <span>{{ topData[topstate].wera }}</span>
              <img
                src="../imger/server/come.png"
                id="come"
                :alt="topData[topstate].wera"
              />
            </div>
          </div>

          <div class="tabBox" @click="fn1(0)">
            <img src="../imger/server/top01.png" id="top01" alt="商标服务" />
            <span class="tabBoxWord">商标服务</span>
            <img src="../imger/server/come.png" id="come" alt="商标服务" />
          </div>
          <div id="lan"></div>
          <div class="tabBox" @click="fn1(1)">
            <img src="../imger/server/top02.png" id="top01" alt="品牌服务" />
            <span class="tabBoxWord">品牌服务</span>
            <img src="../imger/server/comeCopy.png" id="come" alt="品牌服务" />
          </div>
          <div id="lan"></div>
          <div class="tabBox" @click="fn1(2)">
            <img src="../imger/server/top03.png" id="top01" alt="专利版权" />
            <span class="tabBoxWord">专利版权</span>
            <img src="../imger/server/comeCopy.png" id="come" alt="专利版权" />
          </div>
          <div id="lan"></div>
          <div class="tabBox" @click="fn1(3)">
            <img src="../imger/server/top04.png" id="top02" alt="其他服务" />
            <span class="tabBoxWord">其他服务</span>
            <img src="../imger/server/comeCopy.png" id="come" alt="其他服务" />
          </div>
        </div>
        <!-- 模块 -->
        <div class="servermoku" v-show="topstate == 0 ? true : false">
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #ff9519"></div>
              <span>商标服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 30.1875rem">
                <img src="../imger/server/list01.png" alt="商标服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #ff971a"
                    v-for="(item, index) in buttomData01"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData01"
                  :key="index"
                  @click="open1(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open1" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #4f8af1"></div>
              <span>商标异议</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 30.1875rem">
                <img src="../imger/server/list02.png" alt="商标异议" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #4e8af1"
                    v-for="(item, index) in buttomData02"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData02"
                  :key="index"
                  @click="open2(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open2" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="servermoku" v-show="topstate == 1 ? true : false">
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #5de1d8"></div>
              <span>品牌设计</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list03.png" alt="品牌设计" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #2eccc1"
                    v-for="(item, index) in buttomData03"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData03"
                  :key="index"
                  @click="open3(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open3" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #835ce8"></div>
              <span>品牌建设</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list04.png" alt="品牌建设" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #835ce8"
                    v-for="(item, index) in buttomData04"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData04"
                  :key="index"
                  @click="open4(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open4" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="servermoku" v-show="topstate == 2 ? true : false">
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #ed8381"></div>
              <span>专利服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list05.png" alt="专利服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #e17371"
                    v-for="(item, index) in buttomData05"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData05"
                  :key="index"
                  @click="open5(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open5" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #fbbf03"></div>
              <span>版权服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list06.png" alt="版权服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #f3ad07"
                    v-for="(item, index) in buttomData06"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData06"
                  :key="index"
                  @click="open6(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open6" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #4d89f1"></div>
              <span>其他服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list07.png" alt="其他服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #4e8af1"
                    v-for="(item, index) in buttomData07"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData07"
                  :key="index"
                  @click="open7(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open7" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="servermoku" v-show="topstate == 3 ? true : false">
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #ff9519"></div>
              <span>企业项目服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 39.375rem">
                <img src="../imger/server/list01.png" alt="企业项目服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #ff971a"
                    v-for="(item, index) in buttomData08"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData08"
                  :key="index"
                  @click="open8(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open8" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serverBox">
            <div class="title">
              <div class="lan" style="background: #4f8af1"></div>
              <span>企业策划服务</span>
            </div>
            <div class="wera">
              <div class="weraLeft" style="height: 20rem">
                <img src="../imger/server/list09.png" alt="企业策划服务" />
                <div class="bottom">
                  <div
                    class="bottomBox"
                    style="color: #4e8af1"
                    v-for="(item, index) in buttomData09"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="weraRight">
                <div
                  class="weraRightBox"
                  v-for="(item, index) in weraData09"
                  :key="index"
                  @click="open9(index)"
                >
                  <div class="left">
                    <img :src="item.icon" :alt="item.title" />
                  </div>
                  <div class="right">
                    <p id="title" class="open9" >
                      {{ item.title }}
                    </p>
                    <p id="wera">{{ item.content }}</p>
                    <p id="jiage">
                      {{ item.picte }}<span id="jiage01">{{ item.bq }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/header.vue";
import Footer from "../components/footer.vue";
import Sidebar from "../components/sidebar.vue";
import Eject from "../components/eject.vue";
import Drawer from "../components/drawer.vue";
import ColumnSearch from "../components/columnSearch.vue";
import topicon01 from "../imger/server/top01.png";
import topicon02 from "../imger/server/top02.png";
import topicon03 from "../imger/server/top03.png";
import topicon04 from "../imger/server/top04.png";

import { message } from "../API/index";

import icon101 from "../imger/server/icon101.png";
import icon102 from "../imger/server/icon102.png";
import icon103 from "../imger/server/icon103.png";
import icon104 from "../imger/server/icon104.png";
import icon105 from "../imger/server/icon105.png";
import icon106 from "../imger/server/icon106.png";
import icon107 from "../imger/server/icon107.png";
import icon108 from "../imger/server/icon108.png";
import icon109 from "../imger/server/icon109.png";

import icon201 from "../imger/server/icon201.png";
import icon202 from "../imger/server/icon202.png";
import icon203 from "../imger/server/icon203.png";
import icon204 from "../imger/server/icon204.png";
import icon205 from "../imger/server/icon205.png";
import icon206 from "../imger/server/icon206.png";
import icon207 from "../imger/server/icon207.png";
import icon208 from "../imger/server/icon208.png";

import icon301 from "../imger/server/icon301.png";
import icon302 from "../imger/server/icon302.png";
import icon303 from "../imger/server/icon303.png";
import icon304 from "../imger/server/icon304.png";
import icon305 from "../imger/server/icon305.png";

import icon401 from "../imger/server/icon401.png";
import icon402 from "../imger/server/icon402.png";
import icon403 from "../imger/server/icon403.png";
import icon404 from "../imger/server/icon404.png";
import icon405 from "../imger/server/icon405.png";
import icon406 from "../imger/server/icon406.png";

import icon501 from "../imger/server/icon501.png";
import icon502 from "../imger/server/icon502.png";
import icon503 from "../imger/server/icon503.png";
import icon504 from "../imger/server/icon504.png";
import icon505 from "../imger/server/icon505.png";

import icon601 from "../imger/server/icon601.png";
import icon602 from "../imger/server/icon602.png";
import icon603 from "../imger/server/icon603.png";
import icon604 from "../imger/server/icon604.png";

import icon701 from "../imger/server/icon701.png";
import icon702 from "../imger/server/icon702.png";
import icon703 from "../imger/server/icon703.png";
import icon704 from "../imger/server/icon704.png";

import icon801 from "../imger/server/icon801.png";
import icon802 from "../imger/server/icon802.png";
import icon803 from "../imger/server/icon803.png";
import icon804 from "../imger/server/icon804.png";
import icon805 from "../imger/server/icon805.png";
import icon806 from "../imger/server/icon806.png";
import icon807 from "../imger/server/icon807.png";
import icon808 from "../imger/server/icon808.png";
import icon809 from "../imger/server/icon809.png";
import icon810 from "../imger/server/icon810.png";
import icon811 from "../imger/server/icon811.png";

import icon901 from "../imger/server/icon901.png";
import icon902 from "../imger/server/icon902.png";
import icon903 from "../imger/server/icon903.png";
import icon904 from "../imger/server/icon904.png";
import icon905 from "../imger/server/icon905.png";
import icon906 from "../imger/server/icon906.png";

export default {
  components: {
    Sidebar,
    Footer,
    Header,
    Drawer,
    Eject,
    ColumnSearch,
    Map,
  },
  data() {
    return {
      topData: [
        {
          icon: topicon01,
          wera: "商标服务",
        },
        {
          icon: topicon02,
          wera: "品牌服务",
        },
        {
          icon: topicon03,
          wera: "专利版权",
        },
        {
          icon: topicon04,
          wera: "其他服务",
        },
      ],
      topstate: 0,
      buttomData01: [
        "商标注册",
        "保险注册",
        "商标变更",
        "商标转让",
        "补发证书",
        "国际商标",
      ],
      weraData01: [
        {
          icon: icon101,
          title: "商标专家辅助注册",
          content: "全程人工1对1服务",
          picte: "580",
          bq: "元",
        },
        {
          icon: icon102,
          title: "商标保险注册",
          content: "不成功免费重新申请",
          picte: "980",
          bq: "元",
        },
        {
          icon: icon103,
          title: "无执照注册商标",
          content: "无营业执照申请注册商标",
          picte: "1000",
          bq: "元",
        },
        {
          icon: icon104,
          title: "集体商标注册",
          content: "集体商标/证明商标注册",
          picte: "2450",
          bq: "元",
        },
        {
          icon: icon105,
          title: "商标转让办理",
          content: "全程人工1对1服务",
          picte: "980",
          bq: "元",
        },
        {
          icon: icon106,
          title: "补发商标注册证",
          content: "补发全新的商标注册证",
          picte: "980",
          bq: "元",
        },
        {
          icon: icon107,
          title: "商标变更申请",
          content: "公司名称/地址发生变更",
          picte: "200",
          bq: "元",
        },
        {
          icon: icon108,
          title: "国际商标注册",
          content: "覆盖全球188个国家/地区",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon109,
          title: "国际商标转让",
          content: "全球一手闲置商标转让",
          picte: "面议",
          bq: "",
        },
      ],
      buttomData02: [
        "驳回复审",
        "到期续展",
        "无效宣告",
        "撤三申请",
        "异议答辩",
      ],
      weraData02: [
        {
          icon: icon201,
          title: "商标驳回复审",
          content: "",
          picte: "2850",
          bq: "元",
        },
        {
          icon: icon202,
          title: "商标到期续展",
          content: "",
          picte: "980",
          bq: "元",
        },
        {
          icon: icon203,
          title: "商标无效宣告",
          content: "商标被模仿/恶意抢注",
          picte: "2850",
          bq: "元",
        },
        {
          icon: icon204,
          title: "商标无效答辩",
          content: "资深商标律师亲自服务",
          picte: "2150",
          bq: "元",
        },
        {
          icon: icon205,
          title: "商标撤三申请",
          content: "商标连续三年未使用",
          picte: "2550",
          bq: "元",
        },
        {
          icon: icon206,
          title: "商标撤三答辩",
          content: "资深商标律师亲自服务",
          picte: "2150",
          bq: "元",
        },
        {
          icon: icon207,
          title: "商标异议申请",
          content: "公告中对近似商标异议",
          picte: "3150",
          bq: "元",
        },
        {
          icon: icon208,
          title: "商标异议答辩",
          content: "资深商标律师亲自服务",
          picte: "1800",
          bq: "元",
        },
      ],
      buttomData03: ["定制命名", "LOGO设计", "VI设计", "包装设计"],
      weraData03: [
        {
          icon: icon301,
          title: "品牌定制命名",
          content: "好听好记/100%注册商标",
          picte: "30000",
          bq: "元起",
        },
        {
          icon: icon302,
          title: "高端原创logo设计",
          content: "多年原创经验设计团队",
          picte: "2380",
          bq: "元起",
        },
        {
          icon: icon303,
          title: "企业VI系统设计",
          content: "基础规范+七大应用系统",
          picte: "30000",
          bq: "元起",
        },
        {
          icon: icon304,
          title: "产品包装原创设计",
          content: "按需求和数量原创定制设计",
          picte: "3850",
          bq: "元起",
        },
        {
          icon: icon305,
          title: "宣传画册设计",
          content: "",
          picte: "2800",
          bq: "元起",
        },
      ],
      buttomData04: [
        "新闻传播",
        "百度百科",
        "口碑系统",
        "搜索占位",
        "全案咨询",
        "维权/打假",
      ],
      weraData04: [
        {
          icon: icon401,
          title: "品牌新闻传播",
          content: "上千家各行业权威媒体",
          picte: "5800",
          bq: "元起",
        },
        {
          icon: icon402,
          title: "品牌百度百科",
          content: "公司/品牌/产品/人物百科",
          picte: "2000",
          bq: "元起",
        },
        {
          icon: icon403,
          title: "品牌口碑系统",
          content: "多平台搜索问答口碑",
          picte: "5000",
          bq: "元起",
        },
        {
          icon: icon404,
          title: "品牌搜索占位",
          content: "品牌全网搜索优先展现",
          picte: "15800",
          bq: "元起",
        },
        {
          icon: icon405,
          title: "品牌全案咨询            ",
          content: "定位/命名/口号/设计/策略",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon406,
          title: "品牌维权/打假",
          content: "证据收集整理+律师代理",
          picte: "10000",
          bq: "元起",
        },
      ],
      buttomData05: [
        "发明专利",
        "新型专利",
        "外观专利",
        "年费代缴",
        "权利恢复",
      ],
      weraData05: [
        {
          icon: icon501,
          title: "发明专利",
          content: "检索+分析+撰写+代理",
          picte: "5850",
          bq: "元起",
        },
        {
          icon: icon502,
          title: "实用新型专利",
          content: "",
          picte: "2350",
          bq: "元起",
        },
        {
          icon: icon503,
          title: "外观设计专利",
          content: "",
          picte: "1250",
          bq: "元起",
        },
        {
          icon: icon504,
          title: "专利年费代缴",
          content: "防止专利未缴费而失效",
          picte: "300",
          bq: "元",
        },
        {
          icon: icon505,
          title: "专利权利恢复            ",
          content: "",
          picte: "1200",
          bq: "元",
        },
      ],
      buttomData06: ["美术作品", "影视作品", "文字作品", "其他作品"],
      weraData06: [
        {
          icon: icon601,
          title: "美术作品版权",
          content: "图画/书法/雕塑类艺术作品",
          picte: "680",
          bq: "元起",
        },
        {
          icon: icon602,
          title: "影视作品版权",
          content: "普通加急35-45个工作日",
          picte: "2850",
          bq: "元起",
        },
        {
          icon: icon603,
          title: "文字作品版权",
          content: "小说/诗词/散文/论文等文字",
          picte: "1150",
          bq: "元起",
        },
        {
          icon: icon604,
          title: "其他版权作品            ",
          content: "建筑/模型/汇编类作品",
          picte: "2850",
          bq: "元起",
        },
      ],
      buttomData07: [
        "软件著作权",
        "知识产权贯标",
        "科技成果转化",
        "高薪企业认定",
      ],
      weraData07: [
        {
          icon: icon701,
          title: "软件著作权",
          content: "加急/约31-35个工作日",
          picte: "900",
          bq: "元起",
        },
        {
          icon: icon702,
          title: "知识产权贯标",
          content: "政府支持/奖励高达30万",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon703,
          title: "科技成果转化",
          content: "一站式促进科技成果转化",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon704,
          title: "高新企业认定            ",
          content: "税收减免/奖励高达100万",
          picte: "面议",
          bq: "",
        },
      ],
      buttomData08: [
        "工商代办",
        "食品许可证",
        "行业资质",
        "电话办理",
        "AAA级企业信用认证",
        "互联网许可证",
        "医药许可证",
        "ISO体系认证",
        "产品检测",
        "欧盟CE认证",
        "征信修复",
      ],
      weraData08: [
        {
          icon: icon801,
          title: "全国工商代办",
          content: "公司注册/变更/代记账",
          picte: "500",
          bq: "元起",
        },
        {
          icon: icon802,
          title: "食品经营许可证",
          content: "",
          picte: "1300",
          bq: "元起",
        },
        {
          icon: icon803,
          title: "AAA级企业信用认证",
          content: "政府采购/企业招投标加分项",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon804,
          title: "互联网许可证",
          content: "网络文化/游戏/ICP许可证",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon805,
          title: "医药行业许可证",
          content: "医疗器械/药品经营/美容院",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon806,
          title: "办理行业资质",
          content: "建筑/工程/劳务/监理类资质",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon807,
          title: "ISO体系认证",
          content: "ISO9001/ISO14001等",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon808,
          title: "产品检测/质检报告",
          content: "CNAS、CMA、CAL",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon809,
          title: "欧盟CE认证",
          content: "",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon810,
          title: "400电话办理",
          content: "号段可选/提升企业形象",
          picte: "面议",
          bq: "",
        },
        {
          icon: icon811,
          title: "企业征信修复",
          content: "修复天眼查/企查查处罚信息",
          picte: "面议",
          bq: "",
        },
      ],
      buttomData09: [
        "电商入驻",
        "网站开发",
        "小程序开发",
        "媒体报道",
        "央视广告",
        "宣传片制作",
      ],
      weraData09: [
        {
          icon: icon901,
          title: "电商店铺入驻/转让",
          content: "",
          picte: "2850",
          bq: "元",
        },
        {
          icon: icon902,
          title: "企业网站开发",
          content: "",
          picte: "980",
          bq: "元",
        },
        {
          icon: icon903,
          title: "小程序/APP开发",
          content: "商标被模仿/恶意抢注",
          picte: "2850",
          bq: "元",
        },
        {
          icon: icon904,
          title: "新闻媒体报道",
          content: "资深商标律师亲自服务",
          picte: "2150",
          bq: "元",
        },
        {
          icon: icon905,
          title: "央视广告",
          content: "商标连续三年未使用",
          picte: "2550",
          bq: "元",
        },
        {
          icon: icon906,
          title: "企业宣传片制作",
          content: "资深商标律师亲自服务",
          picte: "2150",
          bq: "元",
        },
      ],
      inputData01: "",
      inputData02: "",
      inputData03: "",
      userID: "",
      userkey: "",
      messagedata: {
        user_nickname: "",
        user_name: "",
        user_phone: "",
        user_id: 0,
        message: "",
        source: "服务中心",
        advisories: "",
        reg_id: "",
        reg_type: "",
        platform: "问标",
        brand_num: "",
      },
    };
  },
  created() {
    if (this.$route.query.serve_state == undefined) {
      this.fn1(0);
    } else {
      this.fn1(this.$route.query.serve_state - 1);
    }
  },
  methods: {
    fn1(data) {
      this.topstate = data;
    },
    async tijiao() {
      if (this.inputData02 == "" && this.inputData01 == "") {
        this.$message({
          showClose: true,
          message: "请输入信息，获取优质服务",
        });
      } else {
        if (sessionStorage.getItem("userkey")) {
          this.userID = JSON.parse(localStorage.getItem("user"));
          this.userkey = sessionStorage.getItem("userkey");
          this.messagedata.user_id = this.userID.id;
          this.messagedata.user_phone = this.userID.phone;
          this.messagedata.user_nickname = this.userID.name;
        } else {
          this.userkey = sessionStorage.getItem("key");
        }
        this.messagedata.message = this.inputData01;
        this.messagedata.user_nickname = this.inputData02;
        const res = await message({
          key: this.userkey,
          phone: this.inputData03,
          user_id: this.userID == "" ? 0 : this.userID.id,
          message: JSON.parse(JSON.stringify(this.messagedata)),
        });
        if (res.data.key) {
          this.$message({
            type: "success",
            message: "信息提交成功，等待专员联系",
          });
          this.inputData02 = "";
          this.inputData01 = "";
          this.inputData03 = "";
        }
      }
    },
    open1(index) {
      switch (index) {
        case 0:
          const zjfdzc = this.$router.resolve({ path: "/coach" });
          window.open(zjfdzc.href, "_blank");
          break;
        case 1:
          const Insurance = this.$router.resolve({ path: "/bxzc" });
          window.open(Insurance.href, "_blank");
          break;
        case 2:
          const License = this.$router.resolve({ path: "/license" });
          window.open(License.href, "_blank");
          break;
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open2(index) {
      switch (index) {
        case 0:
          const reject = this.$router.resolve({ path: "/reject" });
          window.open(reject.href, "_blank");
          break;
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open3(index) {
      switch (index) {
        case 1:
          const logo = this.$router.resolve({ path: "/logo" });
          window.open(logo.href, "_blank");
          break;
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open4(index) {
      switch (index) {
        case 3:
          const station = this.$router.resolve({ path: "/seat" });
          window.open(station.href, "_blank");
          break;
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open5(index) {
      switch (index) {
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open6(index) {
      switch (index) {
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open7(index) {
      switch (index) {
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open8(index) {
      switch (index) {
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
    open9(index) {
      switch (index) {
        case 1:
          const wzjs = this.$router.resolve({ path: "/wzjs" });
          window.open(wzjs.href, "_blank");
          break;
        default:
          this.$alert("此功能暂未开通", "消息", {
            confirmButtonText: "确定",
            callback: () => {
              this.$message({
                type: "info",
                message: `感谢您的谅解`,
              });
            },
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.context {
  width: 100%;
  .backBigBox {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
    overflow: hidden;
    #backBox {
      width: 625rem /* 10000px -> 625rem */;
      height: 100%;
      display: flex;
      transition-duration: 0.3s;
      position: relative;
      left: 0;
      .back {
        width: 120rem /* 1920px -> 120rem */;
        height: 28.75rem /* 460px -> 28.75rem */;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .backBox {
          width: 74.5rem /* 1192px -> 74.5rem */;
          height: 8.125rem /* 130px -> 8.125rem */;
          border-radius: 0.3125rem /* 5px -> 0.3125rem */;
          background-color: #f3f5f7;
          position: absolute;
          left: 50%;
          bottom: 4.1875rem /* 67px -> 4.1875rem */;
          transform: translateX(-50%);
          padding: 1.25rem /* 20px -> 1.25rem */;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .BackBoxTitle {
            font-size: 0.9375rem /* 15px -> 0.9375rem */;
            color: #666;
          }
          .backBoxinput {
            width: 100%;
            height: 3.125rem /* 50px -> 3.125rem */;
            display: flex;
            justify-content: space-between;
            .input01 {
              width: 15.625rem /* 250px -> 15.625rem */;
              height: 100%;
              padding: 0.9375rem /* 15px -> 0.9375rem */;
              box-sizing: border-box;
              border: 0.0625rem /* 1px -> 0.0625rem */ solid #e2e2e2;
              border-radius: 0.3125rem /* 5px -> 0.3125rem */;
              outline: none;
            }
            .inputBottom {
              display: flex;

              .input02 {
                width: 19.375rem /* 310px -> 19.375rem */;
                height: 100%;
                padding: 0.9375rem /* 15px -> 0.9375rem */;
                box-sizing: border-box;
                border: 0.0625rem /* 1px -> 0.0625rem */ solid #e2e2e2;
                border-top-left-radius: 0.3125rem /* 5px -> 0.3125rem */;
                border-bottom-left-radius: 0.3125rem /* 5px -> 0.3125rem */;
                outline: none;
              }
              #bottom01 {
                width: 9.1875rem /* 147px -> 9.1875rem */;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                font-size: 0.875rem /* 14px -> 0.875rem */;
                border-top-right-radius: 0.3125rem /* 5px -> 0.3125rem */;
                border-bottom-right-radius: 0.3125rem /* 5px -> 0.3125rem */;
                background: #fc780e;
                border: none;
                cursor: pointer;
                img {
                  width: 1.125rem /* 18px -> 1.125rem */;
                  height: 1.125rem /* 18px -> 1.125rem */;
                  margin-right: 0.9375rem /* 15px -> 0.9375rem */;
                }
              }
            }
            #bottom02 {
              width: 9.1875rem /* 147px -> 9.1875rem */;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fc780e;
              font-size: 0.875rem /* 14px -> 0.875rem */;
              border-radius: 0.3125rem /* 5px -> 0.3125rem */;
              border: 0.125rem /* 2px -> 0.125rem */ solid #fc780e;
              img {
                width: 1.3125rem /* 21px -> 1.3125rem */;
                height: 1.3125rem /* 21px -> 1.3125rem */;
                margin-right: 0.9375rem /* 15px -> 0.9375rem */;
              }
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tab {
      height: 6.25rem /* 100px -> 6.25rem */;
      margin-top: 3.125rem /* 50px -> 3.125rem */;
      background-color: #eaebeb;
      border-radius: 0.3125rem /* 5px -> 0.3125rem */;
      display: flex;
      align-items: center;
      position: relative;
      .topback {
        width: 19.25rem /* 308px -> 19.25rem */;
        height: 6.625rem /* 106px -> 6.625rem */;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 10;
        transition-duration: 0.3s;
        .topbackBox {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 20;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          color: #fff;
          font-size: 1.375rem /* 22px -> 1.375rem */;
          #top01 {
            width: 2.75rem /* 44px -> 2.75rem */;
            height: 3rem /* 48px -> 3rem */;
          }
          #come {
            width: 1.375rem /* 22px -> 1.375rem */;
            height: 1.375rem /* 22px -> 1.375rem */;
          }
        }
        #topback {
          width: 100%;
          height: 100%;
        }
      }

      #lan {
        width: 0.0625rem /* 1px -> 0.0625rem */;
        height: 3.75rem /* 60px -> 3.75rem */;
        background-color: #dddddd;
      }
      .tabBox {
        width: 18.75rem /* 300px -> 18.75rem */;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        position: relative;
        cursor: pointer;
        color: #666666;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        #top01 {
          width: 2.75rem /* 44px -> 2.75rem */;
          height: 3rem /* 48px -> 3rem */;
        }
        #top02 {
          width: 2.1875rem /* 35px -> 2.1875rem */;
          height: 3.3125rem /* 53px -> 3.3125rem */;
        }
        #come {
          width: 1.375rem /* 22px -> 1.375rem */;
          height: 1.375rem /* 22px -> 1.375rem */;
        }
        .tabBoxWord:hover {
          color: #4f8af1;
        }
      }
    }
    .servermoku {
      width: 74.375rem /* 1190px -> 74.375rem */;
      margin-top: 3.4375rem /* 55px -> 3.4375rem */;
      margin-bottom: 3.4375rem /* 55px -> 3.4375rem */;
      .serverBox {
        width: 100%;
        margin-bottom: 2.75rem /* 44px -> 2.75rem */;
        .title {
          width: 100%;
          height: 1.875rem /* 30px -> 1.875rem */;
          display: flex;
          align-items: center;
          font-size: 1.375rem /* 22px -> 1.375rem */;
          color: #333;
          .lan {
            width: 0.3125rem /* 5px -> 0.3125rem */;
            height: 100%;
            border-radius: 0.3125rem /* 5px -> 0.3125rem */;
            margin-right: 1.375rem /* 22px -> 1.375rem */;
          }
        }
        .wera {
          width: 100%;
          margin-top: 1.875rem /* 30px -> 1.875rem */;
          display: flex;
          justify-content: space-between;
          .weraLeft {
            width: 13rem /* 208px -> 13rem */;
            position: relative;

            img {
              width: 100%;
              height: 100%;
            }
            .bottom {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              padding: 0.625rem /* 10px -> 0.625rem */;
              box-sizing: border-box;
              position: absolute;
              top: 7.6875rem /* 123px -> 7.6875rem */;
              left: 0;
              .bottomBox {
                min-width: 5.625rem /* 90px -> 5.625rem */;
                height: 1.9375rem /* 31px -> 1.9375rem */;
                padding-left: 0.3125rem /* 5px -> 0.3125rem */;
                padding-right: 0.3125rem /* 5px -> 0.3125rem */;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: 0.9688rem /* 15.5px -> 0.9688rem */;
                font-size: 0.875rem /* 14px -> 0.875rem */;
                text-align: center;
                line-height: 1.9375rem /* 31px -> 1.9375rem */;
                margin-bottom: 0.625rem /* 10px -> 0.625rem */;
                margin-right: 0.125rem /* 2px -> 0.125rem */;
              }
            }
          }
          .weraRight {
            width: 60.625rem /* 970px -> 60.625rem */;
            display: flex;
            flex-wrap: wrap;
            box-shadow: 0 0 0.1875rem /* 3px -> 0.1875rem */ 0.1875rem
              /* 3px -> 0.1875rem */ rgba($color: #000000, $alpha: 0.07);
            border-radius: 0.625rem /* 10px -> 0.625rem */;
            .weraRightBox:hover {
              .right {
                .open1 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #ff9519 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open2 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #4f8af1 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open3 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #5de1d8 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open4 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #835ce8 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open5 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #ed8381 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open6 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #fbbf03 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open7 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #4d89f1 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open8 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #ff9519 !important;
                }
              }
            }
            .weraRightBox:hover {
              .right {
                .open9 {
                  font-size: 1.3125rem /* 21px -> 1.3125rem */ !important;
                  color: #4f8af1 !important;
                }
              }
            }
            .weraRightBox {
              width: 20rem /* 320px -> 20rem */;
              height: 10rem /* 160px -> 10rem */;
              display: flex;
              align-items: center;
              cursor: pointer;
              transition-duration: 0.1s;

              .left {
                margin-left: 3.375rem /* 54px -> 3.375rem */;
                margin-right: 2.75rem /* 44px -> 2.75rem */;
                .img {
                  width: 2.625rem /* 42px -> 2.625rem */;
                  height: 2.6875rem /* 43px -> 2.6875rem */;
                }
              }
              .right {
                height: 6.25rem /* 100px -> 6.25rem */;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                #title {
                  font-size: 1.25rem /* 20px -> 1.25rem */;
                  font-weight: bold;
                  color: #333;
                }

                #wera {
                  font-size: 0.875rem /* 14px -> 0.875rem */;
                  color: #666666;
                }
                #jiage {
                  color: #ff7e00;
                  font-size: 1.875rem /* 30px -> 1.875rem */;
                  #jiage01 {
                    color: #666666;
                    font-size: 1.25rem /* 20px -> 1.25rem */;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>